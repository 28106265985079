#main-banner {
    height: 128px;
    width: 100%;
    background: rgb(255, 255, 255);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

#audio-player-banner {
    height: 132px;
    width: 100%;
    position: fixed;
    bottom: 0;
}

#non-img-container {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
}

#current-episode-img {
    height: 128px;
}
