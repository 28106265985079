.filteredSearch {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  position: relative;
  top: 15px;
}
.inputAndButton {
  position: absolute;
}
.inputAndButton > input {
  font-size: 1rem;
  padding: 0.5rem 1rem;
  border: 2px solid black;
  margin-right: 15px;
  flex: 2px;
}
.inputAndButton > input:focus {
  border: 3px solid black;
  outline: none;
}
.searchButton {
  position: absolute;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  background-color: black;
  color: white;
  border: none;
  border-radius: 0rem;
  cursor: pointer;
}
.searchResults {
  display: flex;
  flex-wrap: wrap;
  margin-top: 5rem;
}
