.searchedPod-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 225px;
  justify-content: center;
  margin-left: 44px;
  margin-bottom: 22px;
}

.searchedPod-body {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  margin: 10px;
}

.black {
  background-color: black;
}
.red {
  background-color: darkred;
}
