.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  color: black;
}

.active {
  background-color: #a9acae;
  transform-style: flat;
}
