.controls {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.controls svg {
    padding: 1vh;
}

#current-episode {
    margin: 1vh;
}

#current-episode-author {
    margin: 1vh;
}